import * as React from "react"

function SvgWordmark(props) {
  return (
    <svg
      id="wordmark_svg__Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 235.3 39.5"
      xmlSpace="preserve"
      {...props}
    >
      <style>
        {".wordmark_svg__st0{fill:#1f1f1f}.wordmark_svg__st1{fill:#ff877b}"}
      </style>
      <path
        className="wordmark_svg__st0"
        d="M3.6 11.7h7.3l3.2 16.9h.1l3.5-16.9h7.6l3.2 16.9h.1l3.7-16.9h7.3l-8 24h-6.9L21.3 20h-.1l-3.6 15.6h-6.9L3.6 11.7zM40.6 9V3.6h7V9h-7zm7 2.7v24h-7v-24h7zM63.7 16.5h-4.2v11.8c0 1.9.9 2.4 2.2 2.4.8 0 1.8-.1 2.1-.1V36c-.9.1-3 .3-4.5.3-6 0-6.8-3.7-6.8-7.2V16.5h-3.4v-4.3h3.4v-7h7v6.9h4.2v4.4zM65.6 3.6h7V14h.1c1.4-1.8 4.4-2.9 6.6-2.9 5 0 7.5 2.9 7.5 7.7v16.8h-7V21.3c0-3.7-2-4.5-3.5-4.5-1.6 0-3.6 1-3.6 5.5v13.4h-7V3.6z"
      />
      <path
        className="wordmark_svg__st1"
        d="M97.5 16.5h-3.7v-4.3h3.7V9.8c0-2.7.9-6.6 6.8-6.6 1.5 0 3.7.2 4.6.5v5.1h-2c-1.2 0-2.4.4-2.4 1.7V12h4.3v4.3h-4.3v19.2h-7v-19zM110.8 11.7h7v2.4c1.5-1.4 4.1-2.3 6.6-2.5v6.2l-.8.1c-4.2.5-5.9 1.2-5.9 3.9v13.9h-7v-24zM126.3 9V3.6h7V9h-7zm7 2.7v23.9h-7V11.7h7zM141.9 25.1c0 4.6 3 6 5.2 6 2.6 0 3.8-1.4 5.8-4.2l5.3 2.7c-2.4 4.5-6.3 6.6-11.6 6.6-7.4 0-11.7-5.1-11.7-11.9 0-8.5 4.5-13.3 11.7-13.3 8.7 0 11.7 6.7 11.7 14h-16.4zm9.4-4.3c-.2-3.5-2.7-4.6-4.8-4.6-2 0-4.5 1.4-4.8 4.6h9.6zM160.2 11.7h7v2.1c1.8-2.1 4.8-2.7 6.9-2.7 5.7 0 7.2 3.3 7.2 6.9v17.7h-7v-15c0-2.3-.7-3.9-3.4-3.9-2.8 0-3.7 1.8-3.7 4v14.9h-7v-24zM207.3 35.6h-6.8v-2.3h-.1c-1.2 1.5-3.3 3-6.4 3-5.8 0-10.4-3.7-10.5-12.4-.1-9.5 5.4-12.8 10.2-12.8 1.9 0 4.5.4 6.6 2.5v-10h7v32zm-12-19.4c-3.3 0-4.8 2.9-4.8 7.5 0 3.9.9 7.5 4.8 7.5 3.6 0 4.9-3.4 4.9-8 0-4.3-1.2-7-4.9-7zM227.2 18.8c-1.4-1.7-4.4-2.6-6.6-2.6-1.8 0-3 .6-3 1.8 0 4.1 14.2.7 14.2 9.7 0 6.3-5.6 8.6-10.8 8.6-5.2 0-8.2-1.5-11.7-4.7l4.4-3.8c2.2 2.1 4.4 3.4 7.6 3.4 1.2 0 3.8-.3 3.8-2.4 0-3.7-14.2-.8-14.2-9.3 0-6 5.3-8.4 10.1-8.4 3.7 0 7.3 1.3 10.6 3.5l-4.4 4.2z"
      />
    </svg>
  )
}

export default SvgWordmark
