import * as React from "react"

function SvgNounLoading(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" {...props}>
      <g fillRule="evenodd" clipRule="evenodd">
        <path d="M69.841 16.414c-1.247 4.813-6.164 7.705-10.978 6.457-4.813-1.248-7.705-6.163-6.459-10.98 1.25-4.812 6.164-7.705 10.979-6.457 4.814 1.248 7.707 6.166 6.458 10.98zM45.908 91.522a4.684 4.684 0 11-9.067-2.352 4.684 4.684 0 019.067 2.352zM14.868 35.747a6.849 6.849 0 014.91 8.346 6.845 6.845 0 11-13.252-3.436 6.85 6.85 0 018.342-4.91zM89.977 59.683a2.52 2.52 0 011.808 3.075 2.523 2.523 0 01-4.881-1.269 2.521 2.521 0 013.073-1.806zM48.736 12.111c1.223 4.408-1.365 8.975-5.773 10.195a8.288 8.288 0 01-10.196-5.778 8.284 8.284 0 015.776-10.193 8.282 8.282 0 0110.193 5.776zM65.565 89.122a3.963 3.963 0 11-7.639 2.115 3.963 3.963 0 017.639-2.115zM11.686 56.844a6.123 6.123 0 017.535 4.27 6.122 6.122 0 01-4.269 7.534 6.124 6.124 0 11-3.266-11.804zM28.499 19.258a7.565 7.565 0 11-10.698.087 7.56 7.56 0 0110.698-.087zM81.578 77.538a3.242 3.242 0 11-4.551 4.62 3.242 3.242 0 014.551-4.62zM19.797 76.515a5.402 5.402 0 017.641-.062 5.406 5.406 0 01.063 7.643 5.4 5.4 0 01-7.641.06 5.402 5.402 0 01-.063-7.641z" />
      </g>
    </svg>
  )
}

export default SvgNounLoading
